<template>
  <v-list two-line color="rgba(0,0,0,0)" :style="`height: ${styleHeight}; overflow: auto`">
    <v-list-item-group v-model="selected" @change="$emit('onChange', selected)" active-class="blue lighten-4">
      <template v-for="(item, index) in items">
        <ChatListItem :item="item" :key="item.name" />
        <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template> 

<script>
export default {
  components: {
    ChatListItem: () => import("./ChatListItem"),
  },
  props: {
    items: Array,
    height: { type: Number, default: 0 },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    styleHeight() {
      if (!this.height) return "70vh";
      return `${this.height - 100 + 28 - (this.$vuetify.breakpoint.smAndDown ? 8 : 0)}px`;
    },
  },
  methods: {
    getDate(d) {
      if (!d) return null;
      return new Date(d).toLocaleDateString("ru-RU");
    },
  },
};
</script>
